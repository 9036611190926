import JSEncrypt from "jsencrypt";
import Constants from "@/constant/constants";

const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCizQ/y1Idr6RUAUAMPRebcf13G
M1KEq6MXaujc/ppq8PQYbCPcxF3Oswe5htRK6gkOqQwFo99HQStyLKMlw4w3McyK
4VuntVgAyye6VrnCYZzAGuzpb44Ud1+sVP7GaExYjqoj5hVrGGy+He7YE/siKmA5
m2f9PvYd27f0Vb41OQIDAQAB
-----END PUBLIC KEY-----`

export const encrypt = (source: string, key = PUBLIC_KEY): string | false => {
  const crypt: JSEncrypt = new JSEncrypt({});
  crypt.setPublicKey(key);
  return crypt.encrypt(source);
}

export const sign = (): string | false => {
  const str = `accesskey_id=${Constants.Sign.accesskey_id}&timestamp=${Math.round((new Date()).getTime() / 1000) - 5}&${Constants.Sign.accesskey_secret}`;
  return encrypt(str);
}

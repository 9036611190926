
import { defineComponent, onBeforeMount, reactive, toRefs, watch } from 'vue'
import { useRoute, RouteLocationMatched } from 'vue-router'
import { compile } from 'path-to-regexp'
import router from '@/router'
export default defineComponent({
  setup () {
    const currentRoute = useRoute()
    const pathCompile = (path: string) => {
      const { params } = currentRoute
      const toPath = compile(path)
      return toPath(params)
    }

    const state = reactive({
      breadcrumbs: [] as Array<RouteLocationMatched>,
      getBreadcrumb: () => {
        let matched = currentRoute.matched.filter((item) => item.name)
        const first = matched[0]
        if (!state.isDashboard(first)) {
          matched = [{ path: '/', name: '首页' } as any].concat(matched)
        }
        state.breadcrumbs = matched.filter((item) => {
          return item.name
        })
      },
      isDashboard (route: RouteLocationMatched) {
        const name = route && route.name
        if (!name) {
          return false
        }
        return name.toString().trim() == '首页'
      },
      handleLink (item: any) {
        const { redirect, path } = item
        if (redirect) {
          router.push(redirect).catch((err) => {
            console.warn(err)
          })
          return
        }

        router.push(pathCompile(path)).catch((err) => {
          console.warn(err)
        })
      }
    })

    watch(() => currentRoute.path, (path) => {
      state.getBreadcrumb()
    })

    onBeforeMount(() => {
      state.getBreadcrumb()
    })

    return {
      ...toRefs(state)
    }
  }

})



import { defineComponent } from 'vue'
import LicenseImg from '@/assets/images/license.png'

export default defineComponent({
  name: "Footer",

  setup () {
    return {
      LicenseImg
    }
  }
})
